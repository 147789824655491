import _defineProperty from "D:/workspace/emind-ui-1.0/node_modules/@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
export default {
  data: function data() {
    return {
      moment: moment,
      datePickerLocale: datePickerLocale,
      dateFormat: 'YYYY-MM-DD',
      search: {
        stationId: localStorage.getItem('stationId'),
        startTime: moment(),
        endTime: moment(),
        equipmentId: '-1',
        signalId: -2,
        length: 900
      },
      timesArray: [],
      equipmentList: [],
      data: [],
      temperature_analysis_chart: null,
      temperature_analysis_option: null
    };
  },
  mounted: function mounted() {
    var token = localStorage.getItem("currentUser_token");

    if (!token && typeof token != "undefined" && token != 0) {
      this.$router.replace({
        path: '/'
      });
      return;
    }

    var id = localStorage.getItem('stationId');

    if (!id && typeof id != "undefined" && id != 0) {
      this.$router.replace({
        path: '/distribution'
      });
    }

    var this_ = this;
    this_.init("voltage-analysis-chart");
    this_.getEquipmentList();
  },
  methods: {
    onChange: function onChange() {
      if (typeof this.search.startTime === 'string') {
        this.search.startTime = this.search.startTime.split(" ")[0];
        this.search.startTime = moment(this.search.startTime);
      }

      if (typeof this.search.endTime === 'string') {
        this.search.endTime = this.search.endTime.split(" ")[0];
        this.search.endTime = moment(this.search.endTime);
      }
    },
    getEquipmentList: function getEquipmentList() {
      var this_ = this;
      var data = {
        typeId: 3,
        stationId: localStorage.getItem('stationId')
      };
      axios({
        method: 'post',
        url: '/emind/config/equipment/search_condition',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.equipmentList = d.data.data;
          this_.equipmentList.sort(this_.sortNum);

          if (this_.equipmentList.length > 0) {
            this_.search.equipmentId = this_.equipmentList[0].id;
            this_.reflush();
          }
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    sortNum: function sortNum(a, b) {
      if (a.typeId == 3 && b.typeId == 3) {
        return (a.num - b.num) * 1;
      } else if (a.typeId == 3) {
        return -1;
      } else {
        return a.typeId - b.typeId;
      }
    },
    reflush: function reflush() {
      var this_ = this;
      var data = {
        stationId: this.search.stationId,
        equipmentId: this.search.equipmentId,
        signalId: this.search.signalId,
        length: this.search.length
      };

      if (this.search.startTime != null && this.search.endTime != null && this.search.startTime.toDate().getTime() > this.search.endTime.toDate().getTime()) {
        this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
        return;
      } else {
        var dataTime = '';

        if (this_.search.startTime != null) {
          dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
          data.startTime = dataTime;
        } else {
          data.startTime = '1970-01-01 00:00:00';
        }

        if (this_.search.endTime != null) {
          dataTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
          data.endTime = dataTime;
        } else {
          if (this_.search.startTime.toDate().getTime() > new Date().getTime()) {} else {
            data.endTime = moment().format('YYYY-MM-DD ') + "23:59:59";
          }
        }
      }

      this_.temperature_analysis_chart.showLoading({
        text: '数据正在加载。。。',
        color: '#4cbbff',
        textColor: '#4cbbff',
        maskColor: 'rgba(61, 72, 85, 0.65)'
      });
      axios({
        method: 'post',
        url: '/emind/history/data/consistency/chart',
        data: data
      }).then(function (d) {
        var _ref, _ref2;

        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        var newOp = this_.temperature_analysis_chart.getOption();
        newOp.series.splice(0, newOp.series.length);
        this_.timesArray.splice(0, this_.timesArray.length);
        this_.averageIncome = 0;
        var time = 0;

        if (d.data.data.length > 0) {
          time = d.data.data[0].recordTime;
        }

        var markAreaData = [];
        var markAreaData1 = [];
        var flag = 0;
        var index = 0;
        this_.data = d.data.data;
        newOp.series.push({
          name: '最高电压',
          type: 'line',
          animation: false,
          connectNulls: false,
          color: '#c74845',
          data: d.data.data.map(function (x) {
            this_.timesArray.push(x.recordTime);

            if (x.maxVoltage === '') {
              return {
                value: [new Date(x.recordTime), x.maxVoltage.toFixed(0)]
              };
            } else {
              return {
                value: [new Date(x.recordTime), x.maxVoltage.toFixed(0)]
              };
            }
          })
        });
        newOp.series.push({
          name: '最低电压',
          type: 'line',
          color: '#064ca1',
          animation: false,
          connectNulls: false,
          data: d.data.data.map(function (x) {
            if (x.minVoltage === '') {
              return {
                value: [new Date(x.recordTime), x.minVoltage.toFixed(0)]
              };
            } else {
              return {
                value: [new Date(x.recordTime), x.minVoltage.toFixed(0)]
              };
            }
          })
        });
        newOp.series.push({
          name: '电芯电压标准差',
          type: 'line',
          color: '#4a99fa',
          yAxisIndex: 1,
          connectNulls: false,
          animation: false,
          data: d.data.data.map(function (x) {
            if (x.cellVoltageVariance === '') {
              return {
                value: [new Date(x.recordTime), x.cellVoltageVariance.toFixed(2)]
              };
            } else {
              return {
                value: [new Date(x.recordTime), x.cellVoltageVariance.toFixed(2)]
              };
            }
          })
        });
        newOp.series.push({
          markArea: {
            data: markAreaData1
          },
          name: '最大压差',
          type: 'line',
          color: '#064ca1',
          yAxisIndex: 1,
          animation: false,
          data: d.data.data.map(function (x) {
            return {
              value: [new Date(x.recordTime), x.maxVoltage.toFixed(0) - x.minVoltage.toFixed(0)]
            };
          })
        });
        newOp.xAxis = [(_ref = {
          nameTextStyle: {
            fontFamily: "微软雅黑"
          },
          axisLine: {
            lineStyle: {
              width: 1 //这里是为了突出显示加上的

            }
          },
          name: '时间',
          min: this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00",
          max: new Date(this_.search.endTime.toDate().getTime() + 86400000 - 1000 - this_.search.endTime.toDate().getTime() % 86400000 - 28800000).Format("yyyy-MM-dd hh:mm:ss"),
          type: 'time'
        }, _defineProperty(_ref, "axisLine", {
          onZero: false
        }), _defineProperty(_ref, "axisLabel", {
          show: false
        }), _defineProperty(_ref, "axisTick", {
          alignWithLabel: true
        }), _ref), (_ref2 = {
          nameTextStyle: {
            fontFamily: "微软雅黑"
          },
          axisLine: {
            lineStyle: {
              width: 1 //这里是为了突出显示加上的

            }
          },
          name: '时间',
          min: this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00",
          max: new Date(this_.search.endTime.toDate().getTime() + 86400000 - 1000 - this_.search.endTime.toDate().getTime() % 86400000 - 28800000).Format("yyyy-MM-dd hh:mm:ss"),
          type: 'time'
        }, _defineProperty(_ref2, "axisLine", {
          onZero: false
        }), _defineProperty(_ref2, "axisLabel", {
          formatter: function formatter(value, index) {
            // 格式化成月/日，只在第一个刻度显示年份
            var date = new Date(value);
            var dateArray = date.Format("yyyy-MM-dd hh:mm:ss").split(" ");
            return dateArray[1] + '\n' + dateArray[0];
          }
        }), _defineProperty(_ref2, "axisTick", {
          alignWithLabel: true
        }), _ref2)];

        if (d.data.data.length < 1) {
          newOp.title = {
            textStyle: {
              fontFamily: "微软雅黑"
            },
            text: '电芯电压一致性分析曲线',
            subtext: '\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r暂无数据\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r暂无数据',
            x: 'center'
          };
          newOp.dataZoom = null;
        } else {
          newOp.title = {
            textStyle: {
              fontFamily: "微软雅黑"
            },
            text: '电芯电压一致性分析曲线',
            subtext: '',
            x: 'center'
          };
          newOp.dataZoom = [{
            show: true,
            realtime: true,
            start: 30,
            end: 70,
            xAxisIndex: [0, 1]
          }, {
            type: 'inside',
            realtime: true,
            start: 30,
            end: 70,
            xAxisIndex: [0, 1]
          }];
        }

        this_.temperature_analysis_chart.hideLoading();
        this_.temperature_analysis_chart.setOption(newOp);
        this_.temperature_analysis_chart.resize();
      }).catch(function (error) {
        this_.temperature_analysis_chart.hideLoading();

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    init: function init(id) {
      var this_ = this;
      this_.flag == 1;
      this_.temperature_analysis_option = {
        title: {
          textStyle: {
            fontFamily: "微软雅黑",
            color: '#E2E2E2'
          },
          text: '电芯电压一致性分析曲线',
          x: 'center'
        },
        legend: {
          textStyle: {
            fontFamily: "微软雅黑",
            color: '#7C868D'
          },
          data: ['最高电压', '最低电压', '电芯电压标准差', '最大压差'],
          x: 'left'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false
          },
          formatter: function formatter(params) {
            if (params[0].value == '' && params[1].value == '') {
              return '没有数据';
            }

            var relVal = '';

            if (params[0].seriesIndex == 0) {
              relVal = '时间：' + params[0].value[0].Format("yyyy-MM-dd hh:mm:ss") + "<br/>" + params[0].seriesName + '：' + params[0].value[1] + 'mV<br/>' + params[1].seriesName + '：' + params[1].value[1] + 'mV<br/>' + params[2].seriesName + '：' + params[2].value[1] + 'mV<br/>' + params[3].seriesName + '：' + params[3].value[1] + 'mV<br/>';
            } else {
              relVal = '时间：' + params[0].value[0].Format("yyyy-MM-dd hh:mm:ss") + "<br/>" + params[2].seriesName + '：' + params[2].value[1] + 'mV<br/>' + params[3].seriesName + '：' + params[3].value[1] + 'mV<br/>' + params[0].seriesName + '：' + params[0].value[1] + 'mV<br/>' + params[1].seriesName + '：' + params[1].value[1] + 'mV<br/>';
            }

            var index = this_.timesArray.indexOf(params[0].value[0].getTime());
            var dd = this_.data.slice(index, index + 1);

            if (dd && dd[0] && dd[0].minIndex) {
              relVal += '最低电压电芯位置： ' + dd[0].minIndex + '节<br/>';
            }

            if (dd && dd[0] && dd[0].maxIndex) {
              relVal += '最高电压电芯位置： ' + dd[0].maxIndex + '节';
            }

            return relVal;
          }
        },
        toolbox: {
          show: true,
          right: 20,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            restore: {},
            saveAsImage: {}
          }
        },
        axisPointer: {
          link: {
            xAxisIndex: 'all'
          }
        },
        grid: [{
          left: 50,
          right: 50,
          top: 50,
          height: 285
        }, {
          left: 50,
          right: 50,
          top: '50%',
          height: 290
        }],
        xAxis: [{
          nameTextStyle: {
            color: '#fff',
            fontFamily: "微软雅黑"
          },
          splitLine: {
            show: false
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#7C868D'
            }
          },
          name: '时间',
          type: 'time',
          show: false,
          min: this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00",
          max: new Date(this_.search.endTime.toDate().getTime() + 86400000 - 1000 - this_.search.endTime.toDate().getTime() % 86400000 - 28800000).Format("yyyy-MM-dd hh:mm:ss"),
          axisTick: {
            alignWithLabel: true
          }
        }, {
          gridIndex: 1,
          nameTextStyle: {
            fontFamily: "微软雅黑"
          },
          splitLine: {
            show: false
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#7C868D'
            }
          },
          name: '时间',
          min: this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00",
          max: new Date(this_.search.endTime.toDate().getTime() + 86400000 - 1000 - this_.search.endTime.toDate().getTime() % 86400000 - 28800000).Format("yyyy-MM-dd hh:mm:ss"),
          type: 'time',
          position: 'top',
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          min: 3800,
          max: 2500,
          nameTextStyle: {
            fontFamily: "微软雅黑"
          },
          axisLine: {
            lineStyle: {
              color: '#7C868D'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#7C868D'
            }
          },
          name: '电压(mV)',
          type: 'value'
        }, {
          nameTextStyle: {
            fontFamily: "微软雅黑"
          },
          axisLine: {
            lineStyle: {
              color: '#7C868D'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#7C868D'
            }
          },
          gridIndex: 1,
          name: '电压(mV)',
          type: 'value',
          nameLocation: 'start'
        }],
        series: [{
          name: '最高电压',
          type: 'line',
          connectNulls: false,
          animation: true,
          lineStyle: {
            width: 1
          },
          data: this_.data.map(function (x) {
            return x.maxVoltage;
          })
        }, {
          name: '最低电压',
          type: 'line',
          connectNulls: false,
          animation: true,
          data: this_.data.map(function (x) {
            return x.minVoltage;
          })
        }, {
          name: '电芯电压标准差',
          type: 'line',
          xAxisIndex: 1,
          yAxisIndex: 1,
          animation: true,
          data: this_.data.map(function (x) {
            return x.cellVoltageVariance;
          })
        }, {
          name: '最大压差',
          type: 'line',
          xAxisIndex: 1,
          yAxisIndex: 1,
          animation: true,
          data: this_.data.map(function (x) {
            return x.maxVoltage - x.minVoltage;
          })
        }]
      };
      this_.temperature_analysis_chart = this_.$echarts.init(document.getElementById(id));
      this_.temperature_analysis_chart.setOption(this_.temperature_analysis_option);
      this_.temperature_analysis_chart.resize();
      setTimeout(function () {
        window.onresize = function () {
          this_.temperature_analysis_chart.resize();
        };
      }, 500);
    },
    searchFn: function searchFn() {
      var this_ = this;
      this_.reflush();
    },
    exportExcel: function exportExcel(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var name = arguments.length > 2 ? arguments[2] : undefined;
      return new Promise(function (resolve, reject) {
        axios({
          method: 'post',
          url: url,
          // 请求地址
          data: options,
          // 参数
          responseType: 'blob' // 表明返回服务器返回的数据类型

        }).then(function (response) {
          resolve(response.data);
          var blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          });
          var fileName = name;

          if (response.headers['content-disposition'].endsWith('.xls"')) {
            fileName = fileName + '.xls';
          } else if (response.headers['content-disposition'].endsWith('.zip"')) {
            fileName = fileName + '.zip';
          }

          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click(); //释放内存

            window.URL.revokeObjectURL(link.href);
          }
        }, function (err) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      });
    },
    exportFn: function exportFn() {
      var this_ = this;
      var data = {
        stationId: this.search.stationId,
        equipmentId: this.search.equipmentId,
        signalId: this.search.signalId,
        length: this.search.length
      };

      if (this.search.startTime != null && this.search.endTime != null && this.search.startTime.toDate().getTime() > this.search.endTime.toDate().getTime()) {
        this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
        return;
      } else {
        var dataTime = '';

        if (this_.search.startTime != null) {
          dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
          data.startTime = dataTime;
        } else {
          data.startTime = '1970-01-01 00:00:00';
        }

        if (this_.search.endTime != null) {
          dataTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
          data.endTime = dataTime;
        } else {
          if (this_.search.startTime.toDate().getTime() > new Date().getTime()) {} else {
            data.endTime = moment().format('YYYY-MM-DD ') + "23:59:59";
          }
        }
      }

      var now = new Date();
      var url = '/emind/history/data/power_soc_chart/export/' + now.getTime();
      this_.exportExcel(url, data, '功率电量-' + now.Format("yyyy-MM-dd_hh-mm-ss"));
    }
  }
};